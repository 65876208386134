import React, { useContext, forwardRef, useState } from "react";
// import motion
import { AnimatePresence, motion } from "framer-motion";
// import transition
import { transition1 } from "../transitions";
// import context
import { CursorContext } from "../context/CursorContext";
import GallerySlider from "../components/GallerySlider";
import { Link } from "react-router-dom";

import Image1_1 from "../img/gallery/1-1.JPG";
import Image1_2 from "../img/gallery/1-2.JPG";
import Image1_3 from "../img/gallery/1-3.JPG";
import Image1_4 from "../img/gallery/1-4.JPEG";
import Image1_5 from "../img/gallery/1-5.JPEG";

import Image2_1 from "../img/gallery/2-1.JPG";
import Image2_2 from "../img/gallery/2-2.jpg";

import Image3_1_cut from "../img/gallery/3-1-cut.JPEG";
import Image3_2_cut from "../img/gallery/3-2-cut.jpeg";
import Image3_3_cut from "../img/gallery/3-3-cut.jpg";

import Image4_1_cut from "../img/gallery/4-1-cut.jpg";
import Image4_2 from "../img/gallery/4-2.JPG";
import Image4_3 from "../img/gallery/4-3.JPG";

import Image5_1_cut from "../img/gallery/5-1-cut.jpg";
import Image5_2 from "../img/gallery/5-2.JPG";
import HeaderDifferentSections from "../components/HeaderDifferentSectionts";

import Image6_1 from "../img/gallery/6-1.jpg";
import Image6_2 from "../img/gallery/6-2.jpg";
import Image6_3 from "../img/gallery/6-3.jpg";
import Image6_4 from "../img/gallery/6-4.jpg";
import Image6_5 from "../img/gallery/6-5.jpg";
import Image6_6 from "../img/gallery/6-6.jpg";

const galleryData = [
  {
    id: 1,
    title: "Landscapes",
    paragraph:
      "Discover the beauty of nature in our Landscapes gallery, featuring breathtaking views and serene scenes from around the world.",
    images: [
      {
        img: Image2_1,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image2_2,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
    ],
  },
  {
    id: 2,
    title: "Family Portraits",
    paragraph:
      "Celebrate the bonds of family with our Family Portraits gallery. Each photograph showcases the unique connections and shared moments that make your family special.",
    images: [
      {
        img: Image3_1_cut,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image3_2_cut,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image3_3_cut,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
    ],
  },
  {
    id: 3,
    title: "Baby Showers",
    paragraph:
      "Capture the joy and love of new beginnings with our Baby Showers gallery. Each image highlights the anticipation and excitement of this special occasion, turning simple moments into cherished memories.",
    images: [
      {
        img: Image4_2,
        link: "https://www.instagram.com/p/C8cF7TtMAqd/?img_index=3",
      },
      {
        img: Image4_1_cut,
        link: "https://www.instagram.com/p/C8cF7TtMAqd/?img_index=1",
      },
      {
        img: Image4_3,
        link: "https://www.instagram.com/p/C8cF7TtMAqd/?img_index=2",
      },
    ],
  },
  {
    id: 4,
    title: "Newborns",
    paragraph:
      "The Newborns gallery is a collection of tender moments, capturing the innocence and beauty of your baby's first days with delicate and precious images.",
    images: [
      {
        img: Image1_1,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image1_2,
        link: "https://www.instagram.com/p/C3WYfJZCvML/?img_index=2",
      },
      {
        img: Image1_3,
        link: "https://www.instagram.com/p/C3WYfJZCvML/?img_index=1",
      },
      {
        img: Image1_4,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image1_5,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
    ],
  },
  {
    id: 5,
    title: "Portfolio Pictures",
    paragraph:
      "Our Portfolio Pictures gallery features professional portraits that capture your unique style and personality.",
    images: [
      {
        img: Image5_2,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image5_1_cut,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
    ],
  },
  {
    id: 6,
    title: "Wedding Pictures",
    paragraph:
      "Our Wedding Pictures gallery features professional photos capturing the unique style and personality of each couple.",
    images: [
      {
        img: Image6_3,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image6_2,
        link: "https://www.instagram.com/p/C9ZqzqXMjC-/",
      },
      {
        img: Image6_5,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image6_1,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image6_4,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
      {
        img: Image6_6,
        link: "https://www.instagram.com/richie_dsouza_photography/",
      },
    ],
  },
];

const GalleryPage = forwardRef((ref) => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  const [selectedCategory, setSelectedCategory] = useState(galleryData[0].id);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(null);

    setTimeout(() => {
      setSelectedCategory(categoryId);
    }, 300);
  };

  const currentCategory = galleryData.find(
    (category) => category.id === selectedCategory
  );

  return (
    <>
      <HeaderDifferentSections />
      <motion.section
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "100%" }}
        transition={transition1}
        className="section"
        ref={ref}
      >
        <div
          className="flex gap-x-6 md:gap-x-10 mx-4 overflow-x-auto whitespace-nowrap justify-start md:justify-center pt-[120px] pb-2 md:pb-12"
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          {galleryData.map((category) => (
            <div
              key={category.id}
              className="text-gray-600 hover:text-primary transition cursor-pointer whitespace-nowrap"
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.title}
            </div>
          ))}
        </div>
        {currentCategory && (
          <AnimatePresence>
            <motion.div
              className="container mx-auto relative"
              key={currentCategory.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="flex flex-col lg:flex-row h-full items-center justify-start gap-x-24 text-center lg:text-left pt-15 lg:pt-0 pb-8">
                {/* Dynamic gallery content based on current state */}
                <div
                  onMouseEnter={mouseEnterHandler}
                  onMouseLeave={mouseLeaveHandler}
                  className="flex flex-col lg:items-start"
                >
                  <h1 className="h1-smaller">{currentCategory.title}</h1>
                  <p className="mb-12 text-[18px] font-light max-w-sm">
                    {currentCategory.paragraph}
                  </p>
                  <Link to="/">
                    <button className="btn mb-[30px] mx-auto lg:mx-0">
                      Go Back
                    </button>
                  </Link>
                </div>
                <GallerySlider galleryPhotos={currentCategory.images} />
              </div>
            </motion.div>
          </AnimatePresence>
        )}
      </motion.section>
    </>
  );
});

export default GalleryPage;
