/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
// import components
import Socials from './Socials';
// import link
import { Link } from 'react-router-dom';
// import cursor context
import { CursorContext } from '../context/CursorContext';
import { motion } from 'framer-motion';
import MobileNavHomePage from './MobileNavHomePage';

const HeaderHomePage = ({ scrollToHome, scrollToAbout, scrollToGallery, scrollToContact }) => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <header className='fixed w-full px-[30px] lg:px-[100px] z-30 h-[80px] lg:h-[100px] flex items-center bg-white/95'>
      <div className='flex flex-col lg:flex-row lg:items-center w-full justify-between'>
        {/* logo */}
        <Link
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          onClick={scrollToHome}
          className='max-w-[300px]'
        >
          <motion.h2 
              className=" text-3xl  text-black"
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              Richard D'Souza<span className='text-black'>.</span>
            </motion.h2>
        </Link>
        {/* nav - initially hidden - show on desktop mode */}
        <nav
          className='hidden xl:flex gap-x-12 font-semibold'
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <a
            onClick={scrollToHome}
            className='text-[#696c6d] hover:text-primary transition cursor-pointer'
          >
            Home
          </a>
          <a
            onClick={scrollToGallery}
            className='text-[#696c6d] hover:text-primary transition cursor-pointer'
          >
            Gallery
          </a>
          <a
            onClick={scrollToAbout}
            className='text-[#696c6d] hover:text-primary transition cursor-pointer'
          >
            About
          </a>
          <a
            onClick={scrollToContact}
            className='text-[#696c6d] hover:text-primary transition cursor-pointer'
          >
            Contact
          </a>
        </nav>
      </div>
      {/* socials */}
      <Socials />
      {/* mobile nav */}
      <MobileNavHomePage
        scrollToHome={scrollToHome} 
        scrollToAbout={scrollToAbout} 
        scrollToGallery={scrollToGallery} 
        scrollToContact={scrollToContact} 
        />
    </header>
  );
};

export default HeaderHomePage;
