import React, { useContext, useEffect } from "react";
// import motion
import { motion } from "framer-motion";
// import transition
import { transition1 } from "../transitions";
// import context
import { CursorContext } from "../context/CursorContext";
// import EmailJS
import emailjs from "@emailjs/browser";
// import toast notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const notificationWorked = () => toast.success("The message has been sent!");
  const notificationNoWorked = () =>
    toast.error("Failed to send the message, please try again.");
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  useEffect(() => {
    emailjs.init("NkIJdgSW9oJPAds3Z");
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kmy2zer",
        "template_2pz2epk",
        e.target,
        "NkIJdgSW9oJPAds3Z"
      )
      .then(
        (result) => {
          console.log(result.text);
          notificationWorked();
        },
        (error) => {
          console.log(error.text);
          notificationNoWorked();
        }
      );

    e.target.reset();
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={transition1}
      className="section bg-white"
    >
      <div className="container mx-auto h-full">
        <div className="flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left">
          {/* bg */}
          <motion.div
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "100%" }}
            transition={transition1}
            className="bg-[#eef7f9] absolute bottom-0 left-0 right-0 top-20 lg:top-72 -z-10"
          ></motion.div>
          {/* text & form */}
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className="lg:flex-1 lg:pt-32 px-4"
          >
            <h1 className="h1">Contact me</h1>
            <p className="mb-12">It will be a pleasure to hear from you!</p>
            {/* form */}
            <form onSubmit={sendEmail} className="flex flex-col gap-y-4 flex-grow">
              <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-x-10">
                <input
                  className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                  type="text"
                  placeholder="Your name"
                  name="from_name"
                  required
                />
                <input
                  className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                  type="email"
                  placeholder="Your email address"
                  name="email"
                  required
                />
              </div>
              <textarea
                className="outline-none border-b border-b-primary h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#757879]"
                placeholder="Your message"
                name="message"
                required
              />
              <div className="mt-auto">
                <button className="btn mb-[30px] mx-auto lg:mx-0 self-start">
                  Send it
                </button>
              </div>
            </form>
          </div>
          {/* image */}
          {/* <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ opacity: 0, y: "100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "100%" }}
            transition={{ transition: transition1, duration: 1.5 }}
            className="lg:flex-1 flex items-end"
          >
            <img src={PersonImg} alt="" className=" md:mt-[136px] w-9/12 mx-auto" />
          </motion.div> */}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </motion.section>
  );
};

export default Contact;
