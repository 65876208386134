import React, { useEffect, useRef, useState } from "react";
import About from "./About";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Home from "./Home";
import AOS from "aos";
import HeaderHomePage from "../components/HeaderHomePage";
import Loading from "../components/Loading"; 
import { AnimatePresence } from "framer-motion";
import "aos/dist/aos.css";

export const Wrapper = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AOS.refresh();
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500); // Set to 3 seconds for loading
    return () => clearTimeout(timer);
  }, []);

  const contactRef = useRef(null);
  const galleryRef = useRef(null);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToContact = () => {
    contactRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToGallery = () => {
    galleryRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <AnimatePresence>
        {isLoading && <Loading />}
      </AnimatePresence>
      {!isLoading && (
        <>
          <HeaderHomePage
            scrollToHome={scrollToHome} 
            scrollToAbout={scrollToAbout} 
            scrollToGallery={scrollToGallery} 
            scrollToContact={scrollToContact} 
          />
          <div ref={homeRef} data-aos="fade-down">
            <Home scrollToGallery={scrollToGallery}/>
          </div>
          <div ref={galleryRef} data-aos="fade-right">
            <Gallery />
          </div>
          <div ref={aboutRef} data-aos="fade-left">
            <About scrollToContact={scrollToContact} />
          </div>
          <div ref={contactRef} data-aos="fade-down">
            <Contact />
          </div>
        </>
      )}
    </>
  );
};
