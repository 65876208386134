import { Swiper, SwiperSlide } from "swiper/react";

// import swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import React, { useContext, useEffect, useRef } from "react";
import { CursorContext } from '../context/CursorContext';
// import { FaExpandArrowsAlt } from "react-icons/fa";

// import required modules
import { Pagination } from "swiper/modules";

const GallerySlider = ({ galleryPhotos }) => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  const paginationRef = useRef(null);  // Correctly initialize useRef

  useEffect(() => {
    if (paginationRef.current) {
      const paginationBullets = paginationRef.current.querySelectorAll('.swiper-pagination-bullet');
      paginationBullets.forEach(bullet => {
        bullet.addEventListener('mouseenter', mouseEnterHandler);
        bullet.addEventListener('mouseleave', mouseLeaveHandler);
      });

      // Cleanup function
      return () => {
        paginationBullets.forEach(bullet => {
          bullet.removeEventListener('mouseenter', mouseEnterHandler);
          bullet.removeEventListener('mouseleave', mouseLeaveHandler);
        });
      };
    }
  }, [mouseEnterHandler, mouseLeaveHandler]);  // Ensure dependencies are correctly listed

  return (
    <>
      <style>
        {`
          .swiper-container {
            height: 550px; // Adjust based on your needs
          }
          @media (max-width: 768px) {
            .swiper-container {
              height: 700px; // Increased height for small screens
            }
          }
          .swiper-pagination-bullet {
            background: black; // Custom bullet color
            opacity: 1;
          }
          .swiper-pagination-bullet-active {
            background: #484B4B; // Active bullet color
          }
          .swiper-pagination {
            text-align: center;
            width: 100%;
            bottom: 10px; // Adjust if necessary
          }
        `}
      </style>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        pagination={{
          clickable: true,
          el: paginationRef.current  // Connect pagination container correctly
        }}
        modules={[Pagination]}
        className="swiper-container w-full rounded-3xl"
        effect="slide" // You can change the effect here
        speed={600} 
      >
        {galleryPhotos.map((image, imageIndex) => (
          <SwiperSlide key={imageIndex}>
            <div className="w-full h-full">
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <img src={image.img} alt="" className="w-full h-full object-cover rounded-3xl shadow-2xl" />
              </a>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-pagination" ref={paginationRef} />
      </Swiper>
    </>
  );
};

export default GallerySlider;
