import React, { useContext } from 'react';
// import icons
import {
  ImFacebook,
  ImYoutube,
} from 'react-icons/im';
// import cursor context
import { CursorContext } from '../context/CursorContext';

import { FaInstagram } from "react-icons/fa";

const Socials = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className='hidden xl:flex ml-24 text-[30px]'
    >
      <ul className='flex gap-x-8'>
      <li>
          <a href='https://www.instagram.com/richie_dsouza_photography/' rel='noreferrer' target='_blank' className="transition-colors duration-300 ease-in-out hover:text-instagram">
            <FaInstagram />
          </a>
        </li>
        <li>
          <a href='https://www.youtube.com/@rideswithrichie'rel='noreferrer' target='_blank' className="transition-colors duration-300 ease-in-out hover:text-youtube">
            <ImYoutube />
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/rideswithrichie' rel='noreferrer' target='_blank' className="transition-colors duration-300 ease-in-out hover:text-facebook">
            <ImFacebook />
          </a> 
        </li>
      </ul>
    </div>
  );
};

export default Socials;
