import React from 'react';

// import routes route & useLocation hook
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Wrapper } from '../pages/Wrapper';
import GalleryPage from '../pages/GalleryPage';

const AnimRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence initial={true} mode='wait'>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={<Wrapper />} />
        <Route path='/galleryPage' element={<GalleryPage />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
