import React, { useContext, useEffect, useState } from 'react';
// import motion
import { motion, AnimatePresence } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';
// cursor context
import { CursorContext } from '../context/CursorContext';

import Image1_1 from '../img/gallery/1-1.JPG';
import Image1_3 from '../img/gallery/1-3.JPG';
import Image1_4 from '../img/gallery/1-4.JPEG';
import Image6_5 from '../img/gallery/6-5.jpg';

const images = [
  { img: Image6_5, link: "https://www.instagram.com/richie_dsouza_photography/" },
  { img: Image1_1, link: "https://www.instagram.com/richie_dsouza_photography/" },
  { img: Image1_3, link: "https://www.instagram.com/richie_dsouza_photography/" },
  { img: Image1_4, link: "https://www.instagram.com/richie_dsouza_photography/" }
];

const Home = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition1}
      className='section h-screen flex flex-col lg:flex-row'
    >
      <div className='flex flex-1 items-center relative'>
        {/* Text & img wrapper */}
        <div className='flex flex-1 flex-col lg:flex-row justify-center items-center'>
          {/* Text */}
          <motion.div
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-50%' }}
            transition={transition1}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='w-full lg:w-1/2 z-10 mt-10 md:mt-10 md:ml-28 flex flex-col justify-center items-center lg:items-start text-center lg:text-left'
          >
            <h1 className='h1'>
              Photographer <br />& Video Maker
            </h1>
            <p className='text-[26px] mt-8 mb-9 lg:text-[36px] font-primary lg:mb-12'>
              ~ Cork, Ireland
            </p>
          </motion.div>
          {/* Image */}
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='w-full lg:w-1/2 md:mr-10 h-full overflow-hidden flex justify-center items-center rounded-3xl'
          >
            <AnimatePresence exitBeforeEnter>
              <motion.img
                key={activeIndex}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1 }}
                src={images[activeIndex].img}
                alt='Decorative'
                className='object-cover h-full w-auto rounded-3xl'
              />
            </AnimatePresence>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Home;
