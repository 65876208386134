import React, { useContext } from 'react';
// import images
import AboutImg from '../img/about/about.png';
// import motion
import { motion } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';
// import cursor context
import { CursorContext } from '../context/CursorContext';

const About = ({ scrollToContact }) => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section'
    >
      <div
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
        className='container mx-auto h-full relative'
      >
        {/* text & img wrapper */}
        <div className='flex flex-col lg:flex-row h-full items-center justify-center gap-x-24 text-center lg:text-left lg:pt-16'>
          {/* image */}
          <div className='flex-1 max-h-96 lg:max-h-max order-2 lg:order-none overflow-hidden rounded-2xl'>
            <img src={AboutImg} alt='' />
          </div>
          {/* text */}
          <motion.div
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}
            className='flex-1 pt-10 md:pt-36 pb-3 md:pb-14 mx-5 md:mx-0 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start'
          >
            <h1 className='h1'>About me</h1>
            <p className='mb-3 md:mb-12 max-w-sm'>
              <strong>My name is Richard D’Souza,</strong> a freelance photographer and video maker based in Cork, covering the whole of Ireland. 
              <strong>I am passionate about what I do</strong> & I am the right person to stand close to you on your special occasion to capture the best moments of your life. 
              <br/>
              <strong>Let’s have a chat…</strong>
            </p>
            <button onClick={scrollToContact} className='btn'>
              Contact me
            </button>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
