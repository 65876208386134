import React from 'react';
import { motion } from 'framer-motion';

const spinnerVariants = {
  animate: {
    rotate: [0, 360],
    transition: {
      repeat: Infinity,
      duration: 1,
      ease: "linear"
    }
  }
};

const textVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 }
};

const Loading = () => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 flex items-center justify-center bg-gradient-to-r from-black to-white z-50"
    >
      <div className="text-center w-1/2">
        <motion.div
          variants={spinnerVariants}
          animate="animate"
          className="w-16 h-16 border-4 border-gray-300 border-t-4 border-t-white rounded-full mx-auto mb-8"
        ></motion.div>
        <motion.h1
          variants={textVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.5 }}
          className="h1 text-black/80"
        >
          Richard D'Souza Presents...
        </motion.h1>
      </div>
    </motion.div>
  );
};

export default Loading;
