import React, { useContext, forwardRef } from 'react';
// import motion
import { motion } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';
// import context
import { CursorContext } from '../context/CursorContext';
import GallerySlider from '../components/GallerySlider';
import { Link } from 'react-router-dom';

import Image1_1 from '../img/gallery/1-1.JPG';
import Image1_2 from '../img/gallery/1-2.JPG';
import Image1_3 from '../img/gallery/1-3.JPG';
import Image1_4 from '../img/gallery/1-4.JPEG';
import Image1_5 from "../img/gallery/1-5.JPEG";

import Image2_1 from '../img/gallery/2-1.JPG';
import Image2_2 from '../img/gallery/2-2.jpg';

import Image3_1_cut from '../img/gallery/3-1-cut.JPEG';
import Image3_2_cut from '../img/gallery/3-2-cut.jpeg';
import Image3_3_cut from '../img/gallery/3-3-cut.jpg';

import Image4_1_cut from '../img/gallery/4-1-cut.jpg';
import Image4_2 from '../img/gallery/4-2.JPG';
import Image4_3 from '../img/gallery/4-3.JPG';

import Image5_1_cut from '../img/gallery/5-1-cut.jpg';
import Image5_2 from '../img/gallery/5-2.JPG';

const galleryDataGeneral = [
  {
    id: 1,
    title: "Gallery",
    paragraph: "This gallery is a collection of simple, heartfelt moments brought to life through passion, showing how deep feelings can make ordinary views extraordinary.",
    images: [
      { img: Image1_1, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image4_2, link: "https://www.instagram.com/p/C8cF7TtMAqd/?img_index=3" },
      { img: Image3_1_cut, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image4_1_cut, link: "https://www.instagram.com/p/C8cF7TtMAqd/?img_index=1" },
      { img: Image2_1, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image1_3, link: "https://www.instagram.com/p/C3WYfJZCvML/?img_index=1" },
      { img: Image4_3, link: "https://www.instagram.com/p/C8cF7TtMAqd/?img_index=2" },
      { img: Image1_4, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image3_2_cut, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image1_5, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image2_2, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image3_3_cut, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image5_2, link: "https://www.instagram.com/richie_dsouza_photography/" },
      { img: Image1_2, link: "https://www.instagram.com/p/C3WYfJZCvML/?img_index=2" }, 
      { img: Image5_1_cut, link: "https://www.instagram.com/richie_dsouza_photography/" },
    ],
  }
];


const Gallery = forwardRef((ref) => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section'
      ref={ref}
    >
      <div className='container mx-auto h-full relative'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start gap-x-24 text-center lg:text-left pt-24 lg:pt-0 pb-8'>
          {/* Dynamic gallery content based on current state */}
          <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ opacity: 0, y: '-80%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-80%' }}
            transition={transition1}
            className='flex flex-col lg:items-start'
          >
            <h1 className='h1'>{galleryDataGeneral[0].title}</h1>
            <p className='mb-12 text-[18px] font-light max-w-sm'>{galleryDataGeneral[0].paragraph}</p>
            <Link to="/galleryPage">
              <button className='btn mb-[30px] mx-auto lg:mx-0'>
                Have a look
              </button>
            </Link>
          </motion.div>
          <GallerySlider galleryPhotos={galleryDataGeneral[0].images} />
        </div>
      </div>
    </motion.section>
  );
});

export default Gallery;
